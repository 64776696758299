class Accordion {
    constructor(options) {
        this.head = options.head;
        this.body = options.body;
        this.icon = document.createElement('i');
        this.icon.classList.add('fal');
        this.icon.classList.add('fa-minus');
        this.head.setAttribute('aria-controls', this.body.id);
        this.head.setAttribute('role', 'button');
        this.head.tabIndex = 0;
        this.head.insertBefore(this.icon, this.head.firstElementChild);
        this.head.addEventListener('click', () => {
            this.toggle();
        });
        this.expanded = options.expanded;
        this.head.addEventListener('keydown', (event) => {
            if (event.key === 'Enter' || event.key === ' ')
                this.toggle();
        });
        // Collapse by default unless URL bar references the accordion head
        if (window.location.hash) {
            const hashTarget = document.querySelector(window.location.hash);
            if (!hashTarget || hashTarget.parentElement !== this.head) {
                this.collapse({ duration: 0 });
            }
        }
        else {
            this.expand({ duration: 0 });
        }
        if (this.expanded) {
            this.expand({ duration: 0 });
        }
        else {
            this.collapse({ duration: 0 });
        }
        window.addEventListener('hashchange', this.handleHashChange.bind(this));
    }
    toggle() {
        if (this.isCollapsed) {
            this.expand();
        }
        else {
            this.collapse();
        }
    }
    expand(options = { duration: 150 }) {
        $svjq(this.body).slideDown(options);
        // slideDown(this.body, { ...options });
        this.head.setAttribute('aria-expanded', 'true');
        // ClassList#replace is not supported in IE and not polyfilled (apparently)
        this.icon.classList.remove('fa-plus');
        this.icon.classList.add('fa-minus');
        this.isCollapsed = false;
    }
    collapse(options = { duration: 150 }) {
        $svjq(this.body).slideUp(options);
        //slideUp(this.body, { ...options });
        this.head.setAttribute('aria-expanded', 'false');
        // ClassList#replace is not supported in IE and not polyfilled (apparently)
        this.icon.classList.remove('fa-minus');
        this.icon.classList.add('fa-plus');
        this.isCollapsed = true;
    }
    handleHashChange() {
        if (window.location.hash) {
            const hashTarget = document.querySelector(window.location.hash);
            if (hashTarget && hashTarget.parentElement === this.head) {
                this.expand();
            }
            else if (hashTarget) {
                // Collapse since we're jumping to an element that is not us
                this.collapse();
            }
        }
    }
}
export default Accordion;
