class Toggler {
    constructor(options) {
        this.name = options.name;
        this.element = document.querySelector(options.selector);
        if (!this.element) {
            throw new Error(`Element does not exist for selector: ${options.selector}`);
        }
    }
    on() {
        this.element.classList.add(`${this.name}--on`);
    }
    off() {
        this.element.classList.remove(`${this.name}--on`);
    }
    toggle() {
        this.element.classList.toggle(`${this.name}--on`);
    }
}
export default Toggler;
