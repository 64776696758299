class Modal {
    constructor(options) {
        const closeButton = options.container.querySelector('.cc-modal__close-button');
        this.container = options.container;
        this.dimmer = options.dimmer;
        this.isVisible = false;
        if (closeButton) {
            closeButton.addEventListener('click', this.hide.bind(this));
        }
        options.trigger.addEventListener('click', this.handleButtonClick.bind(this));
        this.container.addEventListener('click', event => {
            if (event.target === this.container) {
                this.hide();
            }
        });
    }
    handleButtonClick(event) {
        event.preventDefault();
        this.show();
    }
    show() {
        const oldStyle = this.container.getAttribute('style') || '';
        // Need to use setAttribute to add `!important` to override Bootstrap
        this.container.setAttribute('style', `${oldStyle}; visibility: visible !important;`);
        this.container.style.opacity = '1';
        this.isVisible = true;
        if (this.dimmer) {
            this.dimmer.on();
        }
        if (typeof this.didShow === 'function') {
            this.didShow();
        }
    }
    hide() {
        const oldStyle = this.container.getAttribute('style') || '';
        this.container.setAttribute('style', oldStyle.replace('visibility: visible !important;', ''));
        this.container.style.opacity = '0';
        this.isVisible = false;
        if (this.dimmer) {
            this.dimmer.off();
        }
        if (typeof this.didHide === 'function') {
            this.didHide();
        }
    }
}
export default Modal;
