function urlParameter(__name, __url) {
    /**
     * 
     * @param {string} name Parameter to get
     * @param {string} url Optional 
     * @return {string | null} value of parameter if exist
     */
    let url = window.location.href;
    let name = __name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    var results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return '';
    };

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export default urlParameter;