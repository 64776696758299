import 'typeface-montserrat';
import './main.scss';
import Anchor from './scripts/anchor';
import SearchBar from './scripts/search_bar';
import Toggler from './scripts/toggler';
import Modal from './scripts/modal';
import Accordion from './scripts/accordion';
import urlParams from './scripts/urlParams';
const searchPageId = '4.7d5dfd1e16855888969359d8';
document.addEventListener('DOMContentLoaded', () => {
    let desktopSearchBar = null;
    let mobileSearchBar = null;
    // On/off toggler for overlay element that dims site
    const dimmer = new Toggler({
        name: 'cc-dimmer',
        selector: '.cc-dimmer'
    });
    // Initialize anchors (adding anchor icon)
    const anchors = document.querySelectorAll('.cc-anchor');
    Array.from(anchors).forEach((anchor) => {
        return new Anchor(anchor.querySelector('a'));
    });
    // Initialize accordion box functionality
    const accordionHeads = document.querySelectorAll('.cc-accordion__head');
    const urlParam = urlParams('searchQuery');
    Array.from(accordionHeads).forEach((head) => {
        const nextSibling = head.nextElementSibling;
        let accordion;
        let isExpanded = false;
        if (urlParam) {
            const textContent = nextSibling.innerText.toLowerCase();
            if (textContent.indexOf(urlParam.toLowerCase()) !== -1) {
                isExpanded = true;
            }
        }
        // Only if the next sibling is an accordion body element
        if (nextSibling.classList.contains('cc-accordion__body')) {
            accordion = new Accordion({
                head: head,
                body: nextSibling,
                expanded: isExpanded
            });
        }
        return accordion;
    });
    // Initialize modal dialogs and triggers
    const modalTriggers = Array.from(document.querySelectorAll('[data-cc-modal]'));
    modalTriggers.forEach((modalButton) => {
        const modalTarget = modalButton.getAttribute('data-cc-modal');
        const modalElement = document.querySelector(`.${modalTarget}`);
        const modal = new Modal({
            dimmer,
            container: modalElement,
            trigger: modalButton
        });
    });
    /**
     * Make sure mobile and desktop search bars are synchronized; if you open the
     * desktop search bar and decrease your browser window to mobile size, the
     * mobile bar should be visible.
     *
     * Also change the search link text when opening/closing the search bar.
     */
    const desktopSearchButton = document.querySelector(`.cc-nav-desktop a[data-node-id="${searchPageId}"]`);
    const mobileSearchButton = document.querySelector(`.cc-nav-mobile a[data-node-id="${searchPageId}"]`);
    if (desktopSearchButton && mobileSearchButton) {
        // Desktop elements
        desktopSearchBar = new SearchBar({
            dimmer,
            barSelector: '.cc-search-bar__desktop',
            buttonSelector: `.cc-nav-desktop a[data-node-id="${searchPageId}"]`
        });
        const desktopSearchContainer = desktopSearchButton.parentElement;
        const desktopSearchIcon = desktopSearchContainer.querySelector('i');
        // Mobile elements
        mobileSearchBar = new SearchBar({
            dimmer,
            barSelector: '.cc-search-bar__mobile',
            buttonSelector: `.cc-nav-mobile a[data-node-id="${searchPageId}"]`
        });
        const mobileSearchContainer = mobileSearchButton.parentElement;
        const mobileSearchIcon = mobileSearchContainer.querySelector('i');
        // Synchronize on open
        desktopSearchBar.didShow = () => {
            if (!mobileSearchBar.isVisible)
                mobileSearchBar.show();
            if (!desktopSearchBar.isVisible)
                desktopSearchBar.show();
            desktopSearchButton.innerText = 'Stäng';
            desktopSearchIcon.classList.remove('fa-search');
            desktopSearchIcon.classList.add('fa-times');
            mobileSearchButton.innerHTML = mobileSearchButton.innerHTML.replace('Sök', 'Stäng');
            mobileSearchIcon.classList.remove('fa-search');
            mobileSearchIcon.classList.add('fa-times');
        };
        mobileSearchBar.didShow = desktopSearchBar.didShow;
        // Synchronize on close
        desktopSearchBar.didHide = () => {
            if (mobileSearchBar.isVisible)
                mobileSearchBar.hide();
            if (desktopSearchBar.isVisible)
                desktopSearchBar.hide();
            desktopSearchButton.innerText = 'Sök';
            desktopSearchIcon.classList.remove('fa-times');
            desktopSearchIcon.classList.add('fa-search');
            mobileSearchButton.innerHTML = mobileSearchButton.innerHTML.replace('Stäng', 'Sök');
            mobileSearchIcon.classList.remove('fa-times');
            mobileSearchIcon.classList.add('fa-search');
        };
        mobileSearchBar.didHide = desktopSearchBar.didHide;
    }
});
