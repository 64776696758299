class Anchor {
    constructor(element) {
        if (!element)
            return;
        const icon = document.createElement('i');
        icon.classList.add('fal');
        icon.classList.add('fa-anchor');
        element.appendChild(icon);
        this.element = element;
    }
}
export default Anchor;
