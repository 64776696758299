/**
 * Search bar toggler
 *
 * Shows and hides search bar at `searchBarSelector` when element at
 * `buttonSelector` is clicked. Dimmer/background element is toggled if a
 * `Toggler` instance is provided for it.
 *
 * Uses height of the search bars first child as the bar height, so it's best
 * not to put any padding on the actual search bar element but instead use a
 * wrapper inside it for content. This is to allow us to set `height: 0` in CSS,
 * preventing the search bar from flashing/appearing on screen before the JS is
 * executed to hide it. Set `this.height` to `this.searchbar.offsetHeight` if
 * this isn't needed.
 */
class SearchBar {
    constructor(options) {
        this.searchBarToggler = document.querySelector(options.buttonSelector);
        this.searchBar = document.querySelector(options.barSelector);
        this.searchBar.tabIndex = -1;
        this.searchBox = this.searchBar.querySelector('input[type="text"]');
        this.searchButton = this.searchBar.querySelector('button');
        this.dimmer = options.dimmer;
        this.isVisible = false;
        this.initHeight();
        this.disableTabFocus();
        document.addEventListener('keydown', event => {
            if (event.key === 'Esc' || event.key === 'Escape') {
                this.hide();
            }
        });
        this.searchBarToggler.addEventListener('click', this.handleButtonClick.bind(this));
        if (this.dimmer) {
            this.dimmer.element.addEventListener('click', this.hide.bind(this));
        }
        this.hide();
    }
    /**
     * Clone search bar element and temporarily insert it into DOM with
     * `visibility: hidden` to be able to get its height even if `display: none`
     * (f.e. in mobile breakpoint).
     */
    initHeight() {
        const clone = this.searchBar.cloneNode(true);
        clone.style.display = 'block';
        clone.style.visibility = 'hidden';
        document.body.appendChild(clone);
        this.height = clone.offsetHeight;
        document.body.removeChild(clone);
    }
    handleButtonClick(event) {
        event.preventDefault();
        if (this.isVisible) {
            this.hide();
        }
        else {
            this.searchBox.focus();
            this.show();
        }
    }
    show() {
        this.searchBar.style.height = `${this.height}px`;
        this.isVisible = true;
        this.enableTabFocus();
        if (this.dimmer)
            this.dimmer.on();
        if (typeof this.didShow === 'function')
            this.didShow();
    }
    hide() {
        this.searchBar.style.height = '0';
        this.isVisible = false;
        this.disableTabFocus();
        if (this.dimmer)
            this.dimmer.off();
        if (typeof this.didHide === 'function')
            this.didHide();
    }
    enableTabFocus() {
        this.searchBox.tabIndex = 0;
        if (this.searchButton) {
            this.searchButton.tabIndex = 0;
        }
    }
    disableTabFocus() {
        this.searchBox.tabIndex = -1;
        if (this.searchButton) {
            this.searchButton.tabIndex = -1;
        }
    }
}
export default SearchBar;
